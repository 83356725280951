import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const COLORS = ['#0F5959', '#BF9B6F', '#1D7373', '#BFAF9B', '#D9C5B4', '#8C3604', '#3F4B59', '#FF0000']; // 添加红色作为最后一个颜色

const descriptions = {
  miuvig_quality: "This metric generally measures the integrity and coverage of the viral genome, ensuring that the identified viral genomes are reliable and accurate.",
  completeness: "This metric evaluates the completeness of the viral genome, typically expressed as a percentage, reflecting whether the sequence covers the entire or most parts of the known viral genome.",
  host_genes: "This part assesses the presence and number of host genes within the viral genome. In many cases, viral genomes may carry host genes, and detecting these genes helps improve the accuracy of viral identification.",
  provirus: "This metric is used to identify and assess the integrated state of the viral genome within the host genome (provirus state), which is crucial for evaluating the completeness and functionality of the viral genome."
};

const PhageCharts = ({ statistics }) => {

  const renderBarChart = (data, title, color, key, hideLegend = false) => {
    const chartData = Object.entries(data).map(([key, value]) => ({
      name: key,
      value: value
    }));

    // 对 Completeness 图表进行特殊处理
    if (key === 'completeness') {
      chartData.sort((a, b) => {
        if (a.name === '100') return 1;
        if (b.name === '100') return -1;
        if (a.name === 'Unknown') return 1;
        if (b.name === 'Unknown') return -1;
        return a.name.localeCompare(b.name);
      });
    }

    return (
      <div className="chart-container">
        <h4 className="chart-title">
          {title}
          <i
            className="bi bi-info-circle icon-spacing"
            data-tooltip-id={`tooltip-${key}`}
            data-tooltip-content={descriptions[key]}
            style={{ cursor: 'pointer' }}
          ></i>
          <ReactTooltip id={`tooltip-${key}`} className="custom-tooltip" />
        </h4>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 70 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" tick={{ angle: -40, textAnchor: 'end' }} />
            <YAxis />
            <Tooltip />
            {!hideLegend && <Legend />}
            <Bar dataKey="value" fill={color} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  const renderPieChart = (data, title, colors, key) => {
    if (!data) {
      return <div>No data available for {title}</div>;
    }

    const total = Object.values(data).reduce((acc, value) => acc + value, 0);
    const chartData = Object.entries(data).map(([key, value]) => ({
      name: key,
      value: value,
      percentage: ((value / total) * 100).toFixed(2) + '%'
    }));

    return (
      <div className="chart-container">
        <h4 className="chart-title">
          {title}
          <i
            className="bi bi-info-circle icon-spacing"
            data-tooltip-id={`tooltip-${key}`}
            data-tooltip-content={descriptions[key]}
            style={{ cursor: 'pointer' }}
          ></i>
          <ReactTooltip id={`tooltip-${key}`} className="custom-tooltip" />
        </h4>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={chartData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={100}
              fill="#8884d8"
              label={({ percentage }) => `${percentage}`}
            >
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={index === 0 ? COLORS[2] : COLORS[5]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  };

  return (
    <div className="checkv-charts border p-3">
      <h4>CheckV Quality Assessment Results</h4>
      <table className="table table-bordered">
        <thead>
          <tr>
            {Object.keys(statistics.checkv_quality).map((key) => (
              <th key={key}>{key}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {Object.values(statistics.checkv_quality).map((value, index) => (
              <td key={index}>{value}</td>
            ))}
          </tr>
        </tbody>
      </table>
      <p>CheckV quality assessment is based on the following metrics:</p>
      <div className="metrics-charts four-in-row">
        {renderPieChart(statistics.miuvig_quality, 'Miuvig Quality', COLORS, 'miuvig_quality')}
        {renderBarChart(statistics.completeness, 'Completeness', COLORS[5], 'completeness', true)}
        {renderBarChart(statistics.host_genes, 'Host Genes', COLORS[3], 'host_genes', true)}
        {renderPieChart(statistics.provirus, 'Provirus', COLORS, 'provirus')}
      </div>
    </div>
  );
};

export default PhageCharts;