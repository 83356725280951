import React, { useEffect, useContext, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import { PhageContext, PhageProvider } from './PhageContext';
import { fetchPhages } from './api';
import DataVisualization from './components/DataVisualization';
import PhageSearchResults from './components/PhageSearchResults';
import PhageDetails from './components/PhageDetails';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import wikiLogo from './image/wiki.png';

const Dashboard = () => {
  const { setPhages } = useContext(PhageContext);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const getPhages = async () => {
      try {
        const data = await fetchPhages();
        setPhages(data);
      } catch (error) {
        console.error('Error fetching phages:', error);
      }
    };
    getPhages();
  }, [setPhages]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    navigate(`/search/id/${searchTerm}`);
  };

  const currentDate = new Date().toLocaleDateString();

  return (
    <div className="container-fluid">
      <main role="main" className="col-md-12 ml-sm-auto col-lg-12 px-4">
        <div className="text-center pt-3 pb-2 mb-3 border-bottom">
          <Link to="/">
            <img src={wikiLogo} alt="Phage Wiki Logo" className="wiki-logo" style={{ cursor: 'pointer' }} />
          </Link>
          <Link to="/" className="text-decoration-none text-dark">
            <h1 className="h2">Phage Wiki</h1>
          </Link>
          <p className="find-your-own-phage">Find Your Own Phage</p>
          <form className="form-inline my-2 my-lg-0" onSubmit={handleSearchSubmit}>
            <input
              className="form-control mr-sm-2"
              type="search"
              placeholder="Search Phage ID"
              aria-label="Search"
              name="searchTerm"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
          </form>
        </div>
        <div className="content">
          <DataVisualization />
        </div>
        <hr />
        <div className="text-center">
          Last Update: {currentDate}
        </div>
      </main>
    </div>
  );
};

const App = () => (
  <PhageProvider>
    <Router>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/search/:field/:value" element={<PhageSearchResults />} />
        <Route path="/phage/:id" element={<PhageDetails />} />
        <Route path="/filter" element={<PhageSearchResults />} />
      </Routes>
    </Router>
  </PhageProvider>
);

export default App;