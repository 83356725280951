import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Header from './Header'; // 导入 Header 组件
import '../App.css'; // 确保导入包含 loader 样式的 CSS 文件
import { API_PHAGES_RBPS_URL, API_PHAGES_URL } from '../constants';

const PhageDetails = () => {
  const { id } = useParams();
  const [phage, setPhage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [rbpData, setRbpData] = useState({ rbp_count: 0, RBP: {} });

  useEffect(() => {
    const fetchPhageDetails = async () => {
      try {
        const phageResponse = await axios.get(`${API_PHAGES_URL}/${id}`);
        setPhage(phageResponse.data);

        const rbpResponse = await axios.get(`${API_PHAGES_RBPS_URL}/${id}`);
        setRbpData(rbpResponse.data);
      } catch (error) {
        console.error('Error fetching phage details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPhageDetails();
  }, [id]);

  if (loading) {
    return (
      <div className="container-fluid">
        <main role="main" className="col-md-12 ml-sm-auto col-lg-12 px-4">
          <Header /> {/* 使用 Header 组件 */}
          <div className="loader"></div> {/* Spinner */}
        </main>
      </div>
    );
  }

  if (!phage) {
    return (
      <div className="container-fluid">
        <main role="main" className="col-md-12 ml-sm-auto col-lg-12 px-4">
          <Header /> {/* 使用 Header 组件 */}
          <div>Phage not found! PhageDetails.js error.</div>
        </main>
      </div>
    );
  }

  const renderTableRows = (data) => {
    return Object.entries(data).map(([key, value]) => (
      <tr key={key}>
        <th>{key}</th>
        <td>
          {typeof value === 'string' ? value :
            typeof value === 'number' ? value.toString() :
              typeof value === 'boolean' ? value ? 'True' : 'False' :
                value === null ? 'N/A' :
                  JSON.stringify(value)}
        </td>
      </tr>
    ));
  };

  return (
    <div className="container-fluid">
      <Header /> {/* 使用 Header 组件 */}
      <div className="content-wrapper">
        <nav className="sidebar">
          <ul>
            <li><a href="#summary">Summary</a></li>
            <li><a href="#genome-structure">Genome Structure</a></li>
            <li><a href="#gene">Gene</a></li>
            <li><a href="#annotation">Annotation / CDS Function</a></li>
            <li><a href="#reference">Reference</a></li>
            <li><a href="#other-information">Other Information</a></li>
          </ul>
        </nav>
        <main className="main-content">
          <section id="summary" className="phage-section">
            <h2 className="section-title">Summary</h2>
            <table className="table">
              <tbody>
                {renderTableRows({
                  ID: phage._id,
                  Length: phage.length,
                  Type: phage.type,
                  Taxonomy: phage.taxonomy,
                  CheckV: phage.checkv_quality,
                })}
              </tbody>
            </table>
          </section>

          <section id="gene" className="phage-section">
            <h2 className="section-title">Gene</h2>
            <table className="table">
              <tbody>
                <tr>
                  <th>Phenotype</th>
                  <td>{phage.phenotype || 'N/A'}</td>
                </tr>
                <tr>
                  <th>DNA Sequence (Link)</th>
                  <td>
                    <a href={`${API_PHAGES_URL}/${phage._id}/download_fasta`} download>
                      Download
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>

          <section id="annotation" className="phage-section">
            <h2 className="section-title">Annotation / CDS Function</h2>
            <table className="table">
              <tbody>
                <tr>
                  <th>RBP_Count</th>
                  <td>
                    {rbpData.rbp_count}
                    {rbpData.rbp_count > 0 && (
                      <a
                        href={`${API_PHAGES_RBPS_URL}/${phage._id}/download_fasta`}
                        download
                        style={{ marginLeft: '10px' }}
                      >
                        Download
                      </a>
                    )}
                  </td>
                </tr>
                {renderTableRows({
                  'CARD AMR Genes': phage.CARD_AMR_Genes,
                  'Defense System': 'Currently not available',
                  CDS: phage.CDS,
                  CRISPRs: phage.CRISPRs,
                  'VFDB Virulence Factors': phage.VFDB_Virulence_Factors,
                  Lysis: phage.lysis,
                  tRNAs: phage.tRNAs,
                })}
              </tbody>
            </table>
          </section>

          <section id="reference" className="phage-section">
            <h2 className="section-title">Reference</h2>
            <table className="table">
              <tbody>
                {renderTableRows(phage.references || {})}
              </tbody>
            </table>
          </section>

          <section id="other-information" className="phage-section">
            <h2 className="section-title">Other Information</h2>
            <table className="table">
              <tbody>
                {renderTableRows({
                  'Contact Information': phage.contact
                })}
              </tbody>
            </table>
          </section>
        </main>
      </div>
    </div>
  );
};

export default PhageDetails;