import React, { createContext, useState } from 'react';

export const PhageContext = createContext();

export const PhageProvider = ({ children }) => {
  const [phages, setPhages] = useState([]);

  return (
    <PhageContext.Provider value={{ phages, setPhages }}>
      {children}
    </PhageContext.Provider>
  );
};