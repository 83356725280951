import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import { useNavigate } from 'react-router-dom';
import { API_PHAGE_STATISTICS_URL } from '../constants';

const COLORS = ['#0F5959', '#BF9B6F', '#1D7373', '#BFAF9B', '#D9C5B4', '#8C3604', '#3F4B59', '#BF0436']; // 添加红色作为最后一个颜色

const DataVisualization = () => {
  const [statistics, setStatistics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // 用于导航

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const response = await axios.get(`${API_PHAGE_STATISTICS_URL}`);
        setStatistics(response.data);
      } catch (error) {
        setError('Error fetching statistics');
        console.error('Error fetching statistics:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStatistics();
  }, []);

  const processHostData = (data) => {
    const others = { name: 'Others (<100 hosts)', value: 0 };
    const processedData = Object.entries(data).reduce((acc, [key, value]) => {
      if (key === 'Unspecified' || value > 100) {
        acc.push({ name: key, value });
      } else {
        others.value += value;
      }
      return acc;
    }, []);
    processedData.push(others);
    processedData.sort((a, b) => (a.name === 'Unspecified' ? 1 : b.name === 'Unspecified' ? -1 : 0));
    return processedData;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!statistics) {
    return <div>No statistics available</div>;
  }

  const handleBarClick = (e, key) => {
    const activePayload = e && e.activePayload;
    if (activePayload && activePayload.length > 0) {
      const data = activePayload[0].payload;
      navigate(`/search/${key}/${data.name}`);
    } else {
      console.error('No active payload found:', e);
    }
  };

  const renderBarChart = (data, title, color, key, height = 340) => {
    const chartData = key === 'host' ? processHostData(data) : Object.entries(data).map(([key, value]) => ({
      name: key,
      value: value
    }));

    return (
      <div className="chart-container" style={{ flex: key === 'host' ? '0 0 100%' : '0 0 calc(33.33% - 1em)', margin: '0.5em' }}>
        <h3 className="chart-title">{title}</h3>
        <ResponsiveContainer width="100%" height={height}>
          <BarChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 100 }} onClick={(e) => handleBarClick(e, key)}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" tick={{ angle: -45, textAnchor: 'end', position: 'insideBottomRight', dx: -10, dy: 10 }} interval={0} />
            <YAxis />
            <Tooltip />
            <Bar dataKey="value" fill={color}>
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.name === 'Others (<100 hosts)' ? COLORS[1] : entry.name === 'Unspecified' ? COLORS[7] : color} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  return (
    <div className="container">
      <div className="grid" style={{ display: 'flex', flexWrap: 'wrap' }}>
        {renderBarChart(statistics.host, 'Host', COLORS[2], 'host', 340)} {/* Host 高度减少15% */}
      </div>
      <div className="grid" style={{ display: 'flex', flexWrap: 'wrap' }}>
        {renderBarChart(statistics.checkv_quality, 'CheckV Quality', COLORS[4], 'checkv_quality')}
        {renderBarChart(statistics.molGC, 'molGC (%)', COLORS[6], 'molGC')}
        {renderBarChart(statistics.length, 'Genome Length (bp)', COLORS[2], 'length')}
      </div>
      <div className="grid" style={{ display: 'flex', flexWrap: 'wrap' }}>
        {renderBarChart(statistics.gene_count, 'Gene Count', COLORS[5], 'gene_count')}
        {renderBarChart(statistics.baltimore_group, 'Baltimore Group', COLORS[1], 'baltimore_group')}
      </div>
    </div>
  );
};

export default DataVisualization;